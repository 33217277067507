body {
  font-family: 'Open Sans', sans-serif !important;
}

.react-autosuggest__container {
  position: relative;
}

.search-input-wrapper {
  position: relative;
}

.search-input-wrapper .react-autosuggest__input {
  width: 100%;
  height: 37px;
  padding: 10px 40px 10px 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  border-radius: 18.5px;
  color: #383a39;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 2px 4px #e4e4e4;
}

.inapp-main-wrapper .MuiLinearProgress-root {
  margin-bottom: 10px;
}

.search-input-wrapper .search-icon {
  position: absolute;
  top: 11px;
  right: 16px;
  width: 14px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: block;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 2px #e4e4e4;
  border-radius: 10px;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: .8rem;
  z-index: 2;
  border-bottom: 1px solid #ccc;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.inapp-main-wrapper {
  margin: 24px 12px !important;
}

.inapp-main-wrapper .main-browser-image {
  margin: 56px auto 32px;
  text-align: center;
}

/*Climate CSS Starts*/

.inapp-main-wrapper .climate-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 2px #e4e4e4;
  border-radius: 8px;
  margin: 10px 0 8px;
  position: relative;
  padding: 15px;
}

.inapp-main-wrapper .climate-wrapper .align-left .align-climate-content {
  padding-left: 50px;
}

.inapp-main-wrapper .climate-wrapper .align-right .align-climate-content {
  padding-right: 5px;
}

.inapp-main-wrapper .climate-wrapper .align-right {
  text-align: right;
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon {
  position: absolute;
  left: 0;
  top: -2px;
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.partly-cloudy-day {
  left: 6px;
  top: 0;
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.partly-cloudy-day {
  top: 1px;
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.rain {
  left: 2px;
  top: -4px;
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.snow {
  position: absolute;
  left: 17px;
  top: 15px;
  -webkit-animation: spin 6s linear infinite;
  animation: spin 6s linear infinite;
  width: 30px;
  height: 30px;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.fog, .inapp-main-wrapper .climate-wrapper .climate-icon .align-climate-icon.wind {
  left: 17px;
  top: 15px;
  -webkit-animation: leftToRight 10s linear infinite alternate;
  animation: leftToRight 10s linear infinite alternate;
  width: 30px;
  height: 30px;
}

@keyframes leftToRight {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-3px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(3px);
  }
}

.inapp-main-wrapper .climate-wrapper .align-climate-content .align-climate {
  display: flex;
  font-size: .8rem;
  font-weight: 600;
  margin-bottom: 3px;
  color: #212121;
}

.inapp-main-wrapper .climate-wrapper .align-climate-content .climate-condition {
  color: #212121;
  font-size: 12px;
  opacity: .6;
}

.inapp-main-wrapper .climate-wrapper .align-climate-content .climate-condition span.weather-icon {
  padding-right: 2px;
  position: relative;
  top: 1px;
}

.inapp-main-wrapper .climate-wrapper .align-climate-content .align-climate .city-name {
  margin-left: 2px;
  text-transform: capitalize;
}

/*Climate CSS Ends*/

/*News Component CSS Starts*/

.news-components-wrapper .news-image {
  width: 92px;
}

.news-components-wrapper .news-image img {
  width: 77px;
  margin-right: 15px;
  border-radius: 5px;
}

.inapp-main-wrapper .news-components-wrapper {
  margin-bottom: 15px;
  border: 1px solid #e4e4e4;
  box-shadow: none;
  border-radius: 8px;
}

.inapp-main-wrapper .news-components-wrapper .news-components-blocks .content-wrapper {
  width: 100%;
}

.news-components-wrapper:last-child {
  margin-bottom: 0;
}

.news-components-wrapper .MuiCardContent-root:last-child {
  padding-bottom: 15px;
}

.news-components-wrapper .title {
  margin-top: 0;
  line-height: 16px;
}

.news-components-wrapper .sub-title {
  line-height: 16px;
}

.news-components-wrapper .title>div {
  font-weight: 600;
  color: #212121;
}

.news-components-wrapper .sub-title>div {
  color: #212121;
  font-size: 12px;
  margin-top: 0;
  opacity: .8;
}

.news-components-wrapper .title>div {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 0;
}

.news-components-wrapper .inline-wrap .site, .news-components-wrapper .inline-wrap .category {
  display: inline-block;
}

.news-components-wrapper .inline-wrap .site, .news-components-wrapper .inline-wrap .category .MuiTypography-body1 {
  font-family: 'Open Sans', sans-serif
}

.news-components-wrapper .inline-wrap .site>span, .news-components-wrapper .inline-wrap .category>span {
  color: #212121;
  margin-bottom: 0;
  opacity: .8;
}

.news-components-wrapper .inline-wrap .site>span {
  font-size: 10px;
}

.news-components-wrapper .inline-wrap .category>span {
  border: 1px solid rgba(154, 160, 166, .6);
  padding: 3px 5px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 8px;
  box-shadow: none;
  text-shadow: none;
}

.news-components-wrapper .inline-wrap .category {
  float: right;
}

.news-components-wrapper .news-components-blocks {
  display: flex;
}

/*News Component CSS Ends*/